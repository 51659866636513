<template>
  <main class="main" data-view="fees">
    <section class="section" id="s1">
      <div class="container">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1">
            <div class="px-xl-5">
              <ui-text tag="5" class="tc-waikawa tw-500 lh-35 ta-center mb-4">
                {{ cc.s1.pre }}
              </ui-text>
              <ui-text tag="1" class="tc-gray lh-35 mb-md-5 ta-center">
                {{ cc.s1.text[0] }}
              </ui-text>
            </div>
          </div>
        </div>
        <div class="row flex-column-reverse flex-lg-row">
          <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-0 col-xl-6 offset-xl-1">
            <ul class="list list--fees"
                v-for="(list1, i) in cc.s1.list.items" :key="`list-1-${i}`"
                :class="`list--${list1.theme}`">
              <li class="mb-5 mb-lg-4">
                <i>
                  <img :src="`/img/icons/ico-currency-${list1.theme}.svg`" alt="shield">
                </i>
                <div>
                  <p class="text ts-18 tw-600 mb-4">{{ list1.text }}</p>
                  <ul class="list list--dot" :class="`list--${list1.theme}`"
                      v-for="(list2, n) in list1.list.items" :key="`list-2-${n}`">
                    <li>
                      <p class="text tc-gray ts-14 ts-lg-16 lh-175">{{ list2.text }}</p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-12 col-lg-5 col-xl-4">
            <div class="img img--fees-s1 mb-4 mb-sm-5 mb-lg-0">
              <img src="/img/pages/fees/img-s1-1.png" alt="img-s1-1">
            </div>
          </div>
          <div class="col-12 col-sm-10 offset-sm-1">
            <ui-text tag="8" class="tc-gray lh-150 mb-md-5">
              {{ cc.s1.text[1] }}
            </ui-text>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--snow" id="s2">
      <comp-contact />
    </section>
    <section class="section">
      <div class="container">
      </div>
    </section>
  </main>
</template>
<script>
import compContact from '@/components/Contact'
import uiText from '@/components/UI/Text'
export default {
  components: {
    compContact, uiText
  },
  computed: {
    cc () {
      return this.$t('pages.fees')
    }
  },
  metaInfo () {
    const meta = this.$t('meta.fees')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { property: 'og:description', content: meta.description }
      ]
    }
  },
  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}
</script>
